import classNames from "classnames";
import color from "common/styles/color";
import { styled, css } from "common/utils/stitches.config";
import { Text } from "components/home-element";
import { authSelector } from "models/auth";
import Head from "next/head";
import Image from "next/legacy/image";
import { useEffect, useState } from "react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-scroll";

const navLinks = [
  {
    url: "home",
    text: "home",
  },
  {
    url: "benefit",
    text: "benefit",
  },
  {
    url: "product",
    text: "product",
  },
  {
    url: "solution",
    text: "solution",
  },
  {
    url: "faq",
    text: "faq",
  },
  {
    url: "contact",
    text: "contact",
  },
];

// function getDashboardClass() {
//   const router = useRouter();
//   if (router.pathname.includes("dashboard"))
//     return styles.insideDashboard().toString();
//   return styles.outsideDashboard().toString();
// }

export default function Header() {
  const { t } = useTranslation();
  const [scrolled, setScrolled] = useState<"active" | "inactive">("inactive");
  const [isActive, setIsActive] = useState<boolean>(false);
  const me = useSelector(authSelector.userSelector);
  const credentials = useSelector(authSelector.credentials);

  useEffect(() => {
    const onScroll = () => {
      setScrolled(window.pageYOffset > 5 ? "active" : "inactive");
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  // useEffect(() => {
  //   if (isActive) {
  //     window.addEventListener("scroll", () => window.scrollTo(0, 0));
  //   }
  // }, [isActive]);
  const expireToken = credentials
    ? Date.now() > credentials?.accessTokenExpires
    : true;
  const placeHolderImage = "/assets/images/default-profile.png";
  return (
    <>
      <Head>
        <title>QRID</title>
      </Head>
      <HeaderContainer scrolled={scrolled}>
        <InnerHeaderContainer>
          <div className={styles.userMobile()}>
            {me && !expireToken ? (
              <a href="/user" className={styles.userContainer()}>
                <Image
                  src={me?.imageUrl ?? placeHolderImage}
                  width={30}
                  height={30}
                  objectFit="cover"
                  style={{ borderRadius: "50%" }}
                  alt={me?.name}
                />
                <span className={styles.userName()}>{me?.name}</span>
              </a>
            ) : (
              <a href="/user/login">
                <button className={styles.loginButton()}>Masuk</button>
              </a>
            )}
          </div>
          <div className={styles.fullLogo()}>
            <Link to="home" offset={-80} className={styles.logoLink()}>
              <Image src="/QRID.svg" alt="qrid" height="30" width="100" />
            </Link>
          </div>
          <NavContainer>
            <div
              className={classNames(styles.hamburger(), isActive && "active")}
              onClick={() => setIsActive(!isActive)}
            >
              <span
                className={classNames(styles.bar(), isActive && "active")}
              />
              <span
                className={classNames(styles.bar(), isActive && "active")}
              />
              <span
                className={classNames(styles.bar(), isActive && "active")}
              />
              {/* <HamburgerMenuIcon color="white" width={32} height={32} /> */}
            </div>
            <MobileNavContainer>
              {navLinks.map((item, idx) => (
                <Link
                  activeClass="active"
                  spy
                  // smooth
                  offset={-80}
                  to={item.url}
                  key={idx}
                  className={styles.link()}
                >
                  <StyledRadioItem>
                    {t(("home." + item.text) as any)}
                    {/* <A>{t(("home." + item.text) as any)}</A> */}
                  </StyledRadioItem>
                </Link>
              ))}
            </MobileNavContainer>
            <div className={styles.userDesktop()}>
              {me ? (
                <a href="/user" className={styles.userContainer()}>
                  <Image
                    src={me?.imageUrl ?? placeHolderImage}
                    width={30}
                    height={30}
                    objectFit="cover"
                    style={{ borderRadius: "50%" }}
                    alt={me?.name}
                  />
                  <span className={styles.userName()}>{me?.name}</span>
                </a>
              ) : (
                <a href="/user/login">
                  <button className={styles.loginButton()}>Masuk</button>
                </a>
              )}
            </div>
          </NavContainer>
          <MobileContainer className={isActive ? "active" : "inactive"}>
            <div style={{ alignSelf: "start", marginLeft: 30 }}>
              <Text
                css={{
                  fontWeight: "600",
                  mb: "30px",
                  color: "#828282",
                  // py: 8,
                  // mt: 16,
                  // mt: "$md",
                }}
              >
                {t("home.menu")}
              </Text>
            </div>

            <div className={styles.mobileLinkContainer()}>
              {navLinks.map((item, idx) => (
                <Link
                  activeClass="active"
                  spy
                  // smooth
                  offset={-80}
                  to={item.url}
                  key={idx}
                  className={styles.mobileLink()}
                  onClick={() => {
                    setIsActive(false);
                  }}
                >
                  <StyledRadioItem>
                    {t(("home." + item.text) as any)}
                  </StyledRadioItem>
                </Link>
              ))}
            </div>
          </MobileContainer>
        </InnerHeaderContainer>
      </HeaderContainer>
    </>
  );
}

const InnerHeaderContainer = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  height: "100%",
  margin: "0 auto",
  flexDirection: "row-reverse",
  "@md": {
    flexDirection: "row",
  },
  "@lg": {
    maxWidth: "80%",
    justifyContent: "space-between",
    gap: 32,
  },
  "@xl": {
    maxWidth: "80%",
  },
});
const HeaderContainer = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingLeft: "$lg",
  paddingRight: "$lg",
  gap: 36,
  color: "black",
  background: "white",
  height: 80,
  position: "sticky",
  top: 0,
  zIndex: 1100,

  "@md": {
    justifyContent: "flex-start",
    px: "$xl",
  },
  "@lg": {
    px: 0,
  },

  variants: {
    scrolled: {
      active: {
        borderBottom: "1px solid #E0E0E0",
      },
      inactive: {
        borderBottom: "1px solid #E0E0E0",
      },
    },
  },

  defaultVariants: {
    scrolled: "inactive",
  },

  "> header": {
    position: "relative",
    height: 80,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "0px auto",
    maxWidth: "90%",
    "@lg": {
      maxWidth: "95%",
    },
  },
});

const styles = {
  logoLink: css({
    display: "flex",
    alignItems: "center",
  }),
  link: css({
    position: "relative",
    textDecoration: "none",
    color: "black",
    userSelect: "none",
    "&::after": {
      position: "absolute",
      display: "block",
      content: "",
      height: 4,
      width: 24,
      background: "#2F80ED",
      borderRadius: 16,
      mx: "auto",
      left: 0,
      right: 0,
      bottom: 4,
      transition: "0.3s",
      transform: "scaleX(0)",
    },
    "@md": {
      "&:hover": {
        "&::after": {
          transform: "scaleX(1)",
        },
        // borderBottom: "2px solid black",
      },
      "&.active": {
        // borderBottom: "2px solid black !important",
        "&::after": {
          transform: "scaleX(1)",
        },
      },
    },
  }),
  mobileLinkContainer: css({
    width: "100%",
    padding: "0px 30px",
    borderTop: "1px solid #E0E0E0",
    "> a div": {
      borderBottom: "1px solid #E0E0E0",
    },
  }),
  mobileLink: css({
    textDecoration: "none",
    color: "black",
    userSelect: "none",
    width: "80%",
    // borderTop: "1px solid #CED0D1",
    // "&:focus": {
    //   background: "red",
    //   opacity: 0.85,
    //   // borderBottom: "2px solid white",
    // },
    "&.active": {
      // borderBottom: "2px solid black",
      // width: 100,
      // color: "$primary",
      color: "$blue",
      // fontWeight: "bold",
    },
  }),
  fullLogo: css({
    width: "fit-content",
    position: "absolute",
    left: 0,
    right: 0,
    margin: "auto",
    "@md": {
      position: "relative",
      margin: 0,
    },
    display: "flex",
    alignItems: "center",
    "&:hover": {
      cursor: "pointer",
    },
  }),
  hamburger: css({
    zIndex: 999,
    "&:hover": {
      cursor: "pointer",
    },
    mb: 0,
    "&.active": {
      mb: 12,
      "& span": {
        "&:nth-child(2)": {
          opacity: 0,
        },
        "&:nth-child(1)": {
          transform: "translateY(14px) rotate(45deg)",
        },
        "&:nth-child(3)": {
          transform: "translateY(0px) rotate(-45deg)",
        },
      },
    },
    "@md": {
      display: "none",
    },
  }),
  active: css({}),
  bar: css({
    color: "#2F80ED",
    display: "block",
    width: 25,
    height: 2,
    margin: "5px auto",
    "-webkit-transition": "all 0.3s ease-in-out",
    transition: "all 0.3s ease-in-out",
    backgroundColor: "#2F80ED",
  }),
  burgerMenu: css({
    color: "$primary",
    mr: 16,
    "@lg": {
      display: "none",
    },
  }),

  insideDashboard: css({
    "@lg": {
      display: "none",
    },
  }),
  outsideDashboard: css({
    display: "flex",
    flexDirection: "column",
  }),
  loginButton: css({
    padding: "10px 0px",
    backgroundColor: "transparent",
    outline: "none",
    border: "none",
    borderRadius: 8,
    color: color.blue1,
    fontWeight: 700,
    "&:hover": {
      cursor: "pointer",
      opacity: 0.8,
    },
  }),
  userContainer: css({
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      cursor: "pointer",
      opacity: 0.8,
    },
  }),
  userName: css({
    color: "#333333",
    fontWeight: 600,
    marginLeft: 10,
    maxWidth: 100,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "none",
    "@lg": {
      display: "block",
    },
  }),
  userDesktop: css({
    display: "none",
    paddingLeft: 16,
    "@md": {
      display: "block",
    },
    "@xl": {
      marginLeft: 12,
    },
  }),
  userMobile: css({
    display: "block",
    "@md": {
      display: "none",
    },
  }),
  userImage: css({
    size: 30,
  }),
  image: css({
    width: "100%",
    height: "100%",
    position: "relative",
    borderRadius: "50%",
    overflow: "hidden",
  }),
};

const NavContainer = styled("div", {
  display: "flex",
  alignItems: "center",
  height: "100%",
  position: "relative",
});
const MobileNavContainer = styled("div", {
  display: "none",
  gap: 0,
  "@md": {
    // position: "absolute",
    left: "auto",
    top: "auto",
    // backgroundColor: "transparent",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  "@xl": {
    gap: 12,
  },
});

const MobileContainer = styled("div", {
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: "white",
  right: 0,
  width: "100%",
  pt: 32,
  pb: "$lg",
  border: "1px solid #EFF5F8",
  height: "100vh",
  left: 0,
  top: 0,
  // : opacity 300ms ease-in, visibility 0s ease-in 300ms;
  // transition: "opacity 300ms ease-in, visibility 0s ease-in 300ms",
  "&.inactive": {
    zIndex: -100,
    // display: "none",
    // opacity: 0,
    // top: "-100%",
    // bottom: "-100%",
    left: "-100vw",
    height: "100vh",
  },
  // display: "none",

  // boxShadow: "0 10px 27px rgba(0, 0, 0, 0.05)",
  "&.active": {
    // opacity: 1,
    transition: "0.5s",

    zIndex: 100,
    left: 0,
    height: "100vh",
    // left: 0,
  },
  "@md": {
    display: "none",
  },
});

const itemStyles = {
  // textAlign: "center",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  padding: "20px 16px",
  color: "#333333",
  fontWeight: 600,
  // borderTop: "1px solid #636465",
  // mt: 16,
  "&:hover": {
    cursor: "pointer",
  },
  // "&:focus": {
  //   opacity: 0.85,
  // },
  "&:focus": {
    outline: "none",
    opacity: 0.85,
    // backgroundColor: "$primary",
    color: "black",
  },
  "@md": {
    mt: 0,
    alignItems: "center",
    display: "flex",
    padding: "10px 16px",
    // borderRadius: 3,
    "&:hover": {
      cursor: "pointer",
    },
    color: "#333333",
    fontWeight: 600,
    "&:focus": {
      outline: "none",
      // backgroundColor: "$primary",
      color: "#333333",
    },
  },
};

const StyledRadioItem = styled("div", itemStyles);
